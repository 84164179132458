/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"
import styles from "./layout.module.css"

import "../styling/reset.css"
import "../styling/fonts.css"
import "../styling/base.css"

import Header from "./header/header"
import Footer from "./footer/footer"

const Layout = ({
  children,
  noFade,
  displayBanner,
  darkMode,
  minimalHeader,
}) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        {/*<link
          href="https://fonts.googleapis.com/css?family=Roboto:300,400"
          rel="stylesheet"
        />*/}
        {/* <script
          src="//code.tidio.co/gh8yodcytblbqvyvvd8vegcztnmxvlme.js"
          async
        ></script> */}
      </Helmet>
      <Header
        minimal={minimalHeader}
        darkMode={darkMode}
        siteTitle={data.site.siteMetadata.title}
        displayBanner={displayBanner}
      />
      {noFade ? (
        <main>{children}</main>
      ) : (
        <main className={styles.fadeIn}>{children}</main>
      )}
      <Footer />
      <script
        async
        defer
        src="https://tools.luckyorange.com/core/lo.js?site-id=306c6cd8"
      ></script>

      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
  window.smartlook||(function(d) {
    var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
    var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
    c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
    })(document);
    smartlook('init', 'c2bd2fc9b30819cfe72b9ce463d2b84313cdb79f', { region: 'eu' });`,
        }}
      ></script>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
						 window._mfq = window._mfq || [];
  (function() {
    var mf = document.createElement("script");
    mf.type = "text/javascript"; mf.defer = true;
    mf.src = "//cdn.mouseflow.com/projects/a240261c-7c67-4b4f-ae9a-bc4855efb642.js";
    document.getElementsByTagName("head")[0].appendChild(mf);
  })();(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);
					`,
        }}
      ></script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
